import isLive from 'services/islive'

export const WHITELIST_COUPONS = [
    'deashli',
    'delidercomunitaria',
    'el-mercadito-sa-ar'
]

export const WHITELIST_DELIVERY_CODE_MERC = [
    3413,
    1,
    3431,
    8259,
    31861,
    16939,
    17121,
    32467,
    32537,
    13856,
    15856,
    34546,
    19068,
    17132,
    36389,
    34421,
    27570,
    4390,
    1214,
    1181,
    1195,
    1561,
    9865,
    36669,
    33508,
    32059,
    15013,
    11730,
    3674,
    12688,
    34065,
    33531,
    29186,
    21740,
]

export const WHITELIST_REFUND_FEATURE = [
    3413,
    1,
    3431,
    8259,
    31861,
    16939,
    17121,
    32467,
    32537,
    13856,
    15856,
    34546,
    19068,
    17132,
    36389,
    34421,
    27570,
    4390,
    1214,
    1181,
    1195,
    1561,
    9865,
    36669,
    33508,
    32059,
    15013,
    11730,
    3674,
    12688,
    34065,
    33531,
    29186,
    21740,
]

export const featureCoupons = async (shop) => {
    const live = await isLive()
    const shopInWhiteList = WHITELIST_COUPONS.includes(shop.slug)
    const countryId = shop.country_id
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: false,
        95: false
    }
    return ((live && shopInWhiteList) || !live || countries[countryId])
}

export const registerUsersOnly = (country) => {
    const countries = {
        1: false,
        2: false,
        180: false,
        46: true,
        59: true,
        95: true
    }
    return (countries[country])
}

export const showMyOrdersButton = (country) => {
    const countries = {
        1: true,
        2: true,
        180: true,
        46: true,
        59: true,
        95: true
    }
    return (countries[country])
}


export const showSearch = async () => {
    const live = await isLive();
    if(live){
        return true;
    }
    return true;
}

export const showFilters = async () => {
    const live = await isLive();
    if(live) return true;
    return true
}


export const isCatalog = (isSG, country) => {
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: false,
        95: false
    }
    return (isSG && countries[country])
}

export const showDrawerOption = async () => {
    const live = await isLive();
    if(live) return false;
    return true;
}

export const showFeaturedProducts = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

export const QuantityOfCardsToShowInHome = async () => {
    const live = await isLive();
    if(live) return 4;
    return 4;
}

export const showFeaturedProductsCart = () => {
    return true
}

export const showBannerReferred = async () => {
    return true
}

export const featureCategoriesCarousel = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

// export const featureUnmissablePromotions = async () => {
//     const live = await isLive();
//     if(live) return false;
//     return true;
// }

export const dateSelector = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: true,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}

export const featureReportPrice = async () => {
    const live = await isLive();
    if(live) return true;
    return false;
}

export const reportPriceByCountry = async (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: false,
        95: false
    }
    return (countries[countryId])
}

export const showPaymentMethods = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: true,
        46: false,
        59: false,
        95: false
    }
    return (countries[countryId])
}

export const showProductNotes = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: true,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}
export const inputLineQuantity = (countryId) => {
    const countries = {
        1: false,
        2: false,
        180: false,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}

export const featureMenues = (countryId) => {
    const countries = {
        1: false,
        2: false,
        180: false,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}

export const featureCancelOrder = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

export const featureChangeProfilePicture = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

export const cashBackGuarantee = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

export const featureTiers = async () => {
    const live = await isLive();
    if(live) return true;
    return true;
}

export const featureSavingTime = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: false,
        95: false
    }
    return (countries[countryId])
}

export const featureContactSupport = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: true,
        95: false
    }
    return (countries[countryId])
}

export const featureWsFloat = (countryId) => {
    const countries = {
        1: true,
        2: true,
        180: false,
        46: false,
        59: false,
        95: false
    }
    return (countries[countryId])
}

export const featureMarkAsDelivered = (countryId, shopId) => {
    const countries = {
        // 1: WHITELIST_DELIVERY_CODE_MERC.includes(shopId),
        // 2: WHITELIST_DELIVERY_CODE_MERC.includes(shopId),
        1: true,
        2: true,
        180: false,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}

export const featureShowHelpButton = (countryId, shopId) => {
    const countries = {
        1: WHITELIST_REFUND_FEATURE.includes(shopId),
        2: WHITELIST_REFUND_FEATURE.includes(shopId),
        180: false,
        46: true,
        59: true,
        95: true
    }
    return (countries[countryId])
}

//Fecha que requerimos marcar como lanzamiento del feature, tomar en cuenta que si la fecha de entrega del pedido es superior a esta fecha de FF, entrará a regir bajo la nueva modalidad ese pedido (En preparación hasta que se marque como entregado)
export const dateFFMarkAsDelivered = (countryId) => {
    switch (countryId) {
        case 1:
            return new Date('01/20/2025').getTime();
        case 2:
            return new Date('01/20/2025').getTime();
        case 46:
            return new Date('12/18/2024').getTime();
        case 59:
            return new Date('10/08/2024').getTime();
        case 95:
            return new Date('01/08/2025').getTime();
        default:
            return new Date('10/08/2024').getTime();
    }
}

export const featureLogout = async () => {
    const live = await isLive();
    if(live) return false;
    return true;
}