import "../styles/globals.css";
import "../styles/StylesBulma.scss";
import { CarritoProvider } from "context/Context";
import { useEffect, useState } from "react";
import { Router, withRouter } from "next/router";
import { trackpage } from "services/gtm";
import Head from "next/head";
import {
  activeCountries,
  countriesInSelector,
  localeCodeByCountryCode,
} from "services/helpers";
import useTranslations from "translations/useTranslations";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import Loader from "componentes/Loader";
import getDatosTienda from "services/getdatostienda";

/* Color themes */
import { ThemeProvider } from "styled-components";
import { themeMercadito } from "themes/mercadito";
import { themeWFP } from "themes/wfp";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 30,
    },
  },
});

function MyApp({ Component, pageProps, router }) {
  const route = router?.state?.query?.route;
  const demo = router?.state?.query?.demo || false;
  const locale = demo || localeCodeByCountryCode[route] || "es-MX";
  const asPath = router?.state?.asPath || "unknown";
  const { messages } = useTranslations(locale);
  const [country, setCountry] = useState(null);
  const [theme, setTheme] = useState(null);
  const [username, setUsername] = useState(null);  

  useEffect(() => {
    const url = new URL(window.location.href);
    if (route === "innovation") {
      if (!url.searchParams.has("demo")) {
        url.searchParams.set("demo", "en-US");
        router.replace(url.toString(), undefined, { shallow: true });
      }
    }
  }, [router.asPath]);

  const themeByCountry = {
    46: themeWFP,
    59: themeWFP,
    95: themeWFP,
  };

  useEffect(() => {
    route &&
      asPath &&
      trackpage({ title: route, url: asPath, guestCheckout: true });
    route &&
      getDatosTienda(route).then((data) => {
        setCountry(data?.data?.country_id);
        setTheme(themeByCountry[data?.data?.country_id] ?? themeMercadito);
      });
    !route && setTheme(themeMercadito);
  }, [route, asPath]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      window.deferredPrompt = e; // Guardamos el evento en una variable global
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  Router.events.on('routeChangeComplete', () => {
    if(window.productFruits){
      window?.productFruits?.pageChanged();
    }
});

  const transformAsPath = asPath.split('?')[0];

  if (messages && theme)
    return (
      <>
        <Head>
          {country && transformAsPath.length > 0 && (
           <link rel="manifest"  
            href={`/api/manifest?${
            [1, 2].includes(country) ? `country_id=${country}` : `country_id=${transformAsPath}`
          }`} 
          /> )}
          <html lang="es-MX" />
          <title>
            {!activeCountries.includes(route)
              ? countriesInSelector.includes(route)
                ? "Mercadito"
                : route ?? "Mercadito"
              : "Mercadito - ofertas siempre"}
          </title>
          <meta
            name="description"
            content={
              !activeCountries.includes(route)
                ? countriesInSelector.includes(route)
                  ? "Las mejores ofertas en productos frescos y de calidad. Compra online y ahorra en cada compra. ¡Mercadito te sirve!. Ahorros y confianza sin moverte de tu casa."
                  : route
                  ? `¡En ${route} tenemos las mejores ofertas!`
                  : "Las mejores ofertas en productos frescos y de calidad. Compra online y ahorra en cada compra. ¡Mercadito te sirve!. Ahorros y confianza sin moverte de tu casa."
                : "Abrí gratis tu tienda virtual y generá ingresos extra todas las semanas."
            }
          />
          <meta name="google" content="notranslate" />
          <meta httpEquiv="content-language" content="es-MX" />
        </Head>
        <QueryClientProvider client={queryClient}>
          <CarritoProvider>
            <IntlProvider
              messages={messages}
              locale={locale}
              defaultLocale="es-MX"
              textComponent={"span"}
            >
              <ThemeProvider theme={theme}>
                <Component {...pageProps} translate="no" />
              </ThemeProvider>
            </IntlProvider>
          </CarritoProvider>
        </QueryClientProvider>
      </>
    );
  return <Loader />;
}

export default withRouter(MyApp);
