// export default async function sendmessage(to, message, country, customerId, orderCode, orderId, sucursal) {
//   fetch('https://api.onesignal.com/notifications?c=push', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       accept: 'application/json',
//       Authorization: `Key ${JSON.parse(process.env.NEXT_PUBLIC_ONE_SIGNAL_API_KEY)[country]}`
//   },
//     body: JSON.stringify({
//       app_id: JSON.parse(process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID)[country],
//       headings: { es: "Pedido confirmado", en: "Order confirmed" },
//       contents: { 
//         es: `Confirmación del pedido Nro ${orderCode} ✅`, 
//         en: `Order confirmation N ${orderCode} ✅`,
//       },
//       data: { url: `/${sucursal}/mis-pedidos/${orderId}`, title: "Pedido confirmado" },
//       url: `/${sucursal}/mis-pedidos/${orderId}`,
//       filters: [
//         { field: "tag", key: "customer_id", relation: "=", value: customerId }
//       ],
//       priority: "high",
//       require_interaction: true,
//       web_push_topic: `pedido_${Date.now()}`,
//       mutable_content: true,
//       web_push_mutable_content: true,
//       chrome_web_notification: {
//         requireInteraction: true,
//       },
//       display_duration: 0,
//       send_after: new Date().toISOString(),
//       ttl: 2419200,
//       channel_for_external_user_ids: "push",
//       chrome_web_icon: "/imgs/newLogoM.svg",
//       actions: [
//         { id: "ver_pedido", title: "Ver pedido" }
//       ],
//       collapse_id: `pedido_${Date.now()}`
//     })
//   })
//   .then(response => response.json())
//   .then(data => {
//     if(data.errors){
//       messageWhatsapp(to, message)
//     }else{
//       console.log("✅ Notificación enviada:", data);
//     }
//   })
//   .catch(err => messageWhatsapp(to, message));
// }

// async function messageWhatsapp(to, message) {
//   const response = await fetch(`/api/sendmessage`, {
//     method: 'POST',
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//     },
//     body: JSON.stringify({
//       to: to.charAt(0) !== '+' ? `+${to}` : to,
//       message: message,
//     }),
//   })
//   const data = await response.json()
//   return data;
// }

export default async function sendmessage(to, message, useContentTemplate, contentSid, templateParams) {
  const response = await fetch(`/api/sendmessage`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      to: to.charAt(0) !== '+' ? `+${to}` : to,
      message: message,
      useContentTemplate,
      contentSid,
      templateParams: JSON.stringify(templateParams)
    }),
  })
  const data = await response.json();
  return data;
};